'use strict'

###*
 # @ngdoc service
 # @name mundoSocket.factory:MundoSocket

 # @description

###
angular
  .module 'mundoSocket'
  .factory 'MundoSocket', [
    'OAuthToken',
    '$rootScope',
    '$q',
    '$log',
    '$window',
    '$interval',
    '$translate',
    'uuid4',
    (OAuthToken, $rootScope, $q, $log, $window, $interval, $translate, uuid4) ->
      autobahn = $window.autobahn

      MundoSocketBase = {}

      MundoSocketBase.options =
        unitMessageConsumer:
          topic: 'mundo_mosa.ws.notifications.unit_messages'
          singleTopic: 'mundo_mosa.ws.notifications.unit_messages.{identifier}'
          dataEventName: 'mundo_mosa.socket_server.message_consumer.unit_message_data'
          enabled: false
          identifiers: true
        unitStatusConsumer:
          topic: 'mundo_mosa.ws.notifications.unit_statuses'
          singleTopic: 'mundo_mosa.ws.notifications.unit_statuses.{identifier}'
          dataEventName: 'mundo_mosa.socket_server.message_consumer.unit_status_data'
          enabled: false
          identifiers: true
        unitEventConsumer:
          topic: 'mundo_mosa.ws.notifications.unit_events'
          singleTopic: 'mundo_mosa.ws.notifications.unit_events.{identifier}'
          dataEventName: 'mundo_mosa.socket_server.message_consumer.unit_event_data'
          enabled: false
          identifiers: true
        tenantEventConsumer:
          topic: 'mundo_mosa.ws.notifications.tenant_events'
          singleTopic: 'mundo_mosa.ws.notifications.tenant_events.{identifier}'
          dataEventName: 'mundo_mosa.socket_server.message_consumer.tenant_event_data'
          enabled: false
          identifiers: true
        subscriptionConsumer:
          topic: 'mundo_mosa.ws.notifications.subscriptions'
          singleTopic: 'mundo_mosa.ws.notifications.subscriptions.{identifier}'
          dataEventName: 'mundo_mosa.socket_server.message_consumer.subscription_data'
          enabled: false
          identifiers: true

      MundoSocketBase.initialized = false
      MundoSocketBase.authId = null
      MundoSocketBase.connection = null
      MundoSocketBase.session = null
      MundoSocketBase.url = null
      MundoSocketBase.timers =
        connection: null
        data: null

      MundoSocketBase.isInitialized = () ->
        return @initialized

      MundoSocketBase.setInitialized = () ->
        @initialized = true

      MundoSocketBase.getAuthId = () ->
        if not @authId?
          @authId = "#{$rootScope.user.username}🐧#{uuid4.generate()}"

        return @authId

      MundoSocketBase.connect = (url) ->
        if url
          if url != @url
            @disconnect()

          @url = url

        if @isConnected()
          @disconnect()

        return $q (resolve, reject) =>
          if not @url
            $log.warn 'Socket: Missing socket URL for connection!'
            reject()

          if not OAuthToken.getAccessToken()
            $log.warn 'Socket: Missing OAuth token for authenticated connection!'
            reject()

          $log.debug 'Socket: Connecting to', @url
          @enableConnectionMonitor()

          @connection = new autobahn.Connection
            url: @url
            realm: 'mundomosa'
            max_retries: -1
            initial_retry_delay: 5
            retry_delay_growth: 1
            authmethods: ['ticket']
            authid: @getAuthId()
            onchallenge: (session, method, extra) ->
              $log.debug "Socket: Authentication challenge received => method: #{method}, extra: ", extra

              if method == 'ticket'
                return OAuthToken.getAccessToken()
              else
                $log.warn "Socket: Unknown authentication method => method: #{method}"

          @connection.onopen = (session) =>
            $log.debug "Socket: Successfully connected to #{@url}"

            @session = session

            @toggleUnitStatusConsumer @options.unitStatusConsumer.enabled
            @toggleUnitMessageConsumer @options.unitMessageConsumer.enabled
            @toggleUnitEventConsumer @options.unitEventConsumer.enabled
            @toggleTenantEventConsumer @options.tenantEventConsumer.enabled
            @toggleSubscriptionConsumer @options.subscriptionConsumer.enabled

            resolve()

          @connection.onclose = (reason, details) =>
            $log.debug "Socket: Disconnected => reason: #{reason}, details: ", details
            @session = null
            reject()

          @connection.open()

      MundoSocketBase.isConnected = () ->
        return @connection? and @connection.isConnected and @session?

      MundoSocketBase.isRetrying = () ->
        return @connection? and @connection.isRetrying

      MundoSocketBase.disconnect = () ->
        if @isConnected()
          @disableConnectionMonitor()

          topics = []
          for id, subscriptions of @session['_subscriptions']
            for subscription in subscriptions
              if subscription.topic?
                topics.push topic
          @unbindTopics topics

          @connection.close()
          @session = null

      MundoSocketBase.enableConnectionMonitor = () ->
        @disableConnectionMonitor()

        @timers.connection = $interval () =>
          if not (@isConnected() or @isRetrying())
            @connect()
        , 60000

      MundoSocketBase.disableConnectionMonitor = () ->
        if @timers.connection
          $interval.cancel @timers.connection
          @timers.connection = null

      MundoSocketBase.bindTopicEvents = (topics, event) ->
        if not @isConnected()
          return

        for topic in topics
          exists = false

          for id, subscriptions of @session['_subscriptions']
            for subscription in subscriptions
              if subscription.topic? and (subscription.topic == topic)
                exists = true

          if exists
            continue

          @session
            .subscribe topic, (args) ->
              data =
                event: args[0]
                sender: 'system'

              $rootScope
                .$broadcast event, data, topic
            .then (result) ->
              $log.debug "Socket: Subscribed to topic #{topic}"
            , (error) ->
              $log.warn 'Socket: Subscribe error => error: ', error

      MundoSocketBase.unbindTopics = (topics) ->
        if not @isConnected()
          return

        for topic in topics
          for id, subscriptions of @session['_subscriptions']
            for subscription in subscriptions
              if subscription.topic? and (subscription.topic == topic)
                @session.unsubscribe subscription
                .then (result) ->
                  $log.debug "Socket: Unsubscribed from topic #{topic}"
                , (error) ->
                  $log.warn 'Socket: Unsubscribe error => error', error

      MundoSocketBase.toggleUnitStatusConsumer = (active, identifiers = @options.unitStatusConsumer.identifiers) ->
        active = active or false
        @options.unitStatusConsumer.enabled = active

        # Important: _ALWAYS_ disable the consumer before overriding the
        # identifiers, because every time the identifiers change they could
        # modify the topics this consumer should bind to
        @disableUnitStatusConsumer()
        @options.unitStatusConsumer.identifiers = identifiers

        if @options.unitStatusConsumer.enabled
          @enableUnitStatusConsumer()

      MundoSocketBase.enableUnitStatusConsumer = () ->
        @bindTopicEvents @getUnitStatusConsumerTopics(), @options.unitStatusConsumer.dataEventName

      MundoSocketBase.disableUnitStatusConsumer = () ->
        @unbindTopics @getUnitStatusConsumerTopics()

      MundoSocketBase.getUnitStatusConsumerTopics = () ->
        if angular.isArray @options.unitStatusConsumer.identifiers
          return (@options.unitStatusConsumer.singleTopic.replace('{identifier}', x) \
            for x in @options.unitStatusConsumer.identifiers)

        [@options.unitStatusConsumer.topic]

      MundoSocketBase.toggleUnitMessageConsumer = (active, identifiers = @options.unitMessageConsumer.identifiers) ->
        active = active or false
        @options.unitMessageConsumer.enabled = active

        # Important: _ALWAYS_ disable the consumer before overriding the
        # identifiers, because every time the identifiers change they could
        # modify the topics this consumer should bind to
        @disableUnitMessageConsumer()
        @options.unitMessageConsumer.identifiers = identifiers

        if active
          @enableUnitMessageConsumer()

      MundoSocketBase.enableUnitMessageConsumer = () ->
        @bindTopicEvents @getUnitMessageConsumerTopics(), @options.unitMessageConsumer.dataEventName

      MundoSocketBase.disableUnitMessageConsumer = () ->
        @unbindTopics @getUnitMessageConsumerTopics()

      MundoSocketBase.getUnitMessageConsumerTopics = () ->
        if angular.isArray @options.unitMessageConsumer.identifiers
          return (@options.unitMessageConsumer.singleTopic.replace('{identifier}', x) \
            for x in @options.unitMessageConsumer.identifiers)

        [@options.unitMessageConsumer.topic]

      MundoSocketBase.toggleUnitEventConsumer = (active, identifiers = @options.unitEventConsumer.identifiers) ->
        active = active or false
        @options.unitEventConsumer.enabled = active

        # Important: _ALWAYS_ disable the consumer before overriding the
        # identifiers, because every time the identifiers change they could
        # modify the topics this consumer should bind to
        @disableUnitEventConsumer()
        @options.unitEventConsumer.identifiers = identifiers

        if active
          @enableUnitEventConsumer()

      MundoSocketBase.enableUnitEventConsumer = () ->
        @bindTopicEvents @getUnitEventConsumerTopics(), @options.unitEventConsumer.dataEventName

      MundoSocketBase.disableUnitEventConsumer = () ->
        @unbindTopics @getUnitEventConsumerTopics()

      MundoSocketBase.getUnitEventConsumerTopics = () ->
        if angular.isArray @options.unitEventConsumer.identifiers
          return (@options.unitEventConsumer.singleTopic.replace('{identifier}', x) \
            for x in @options.unitEventConsumer.identifiers)

        [@options.unitEventConsumer.topic]

      MundoSocketBase.toggleTenantEventConsumer = (active, identifiers = @options.tenantEventConsumer.identifiers) ->
        active = active or false
        @options.tenantEventConsumer.enabled = active

        # Important: _ALWAYS_ disable the consumer before overriding the
        # identifiers, because every time the identifiers change they could
        # modify the topics this consumer should bind to
        @disableTenantEventConsumer()
        @options.tenantEventConsumer.identifiers = identifiers

        if active
          @enableTenantEventConsumer()

      MundoSocketBase.enableTenantEventConsumer = () ->
        @bindTopicEvents @getTenantEventConsumerTopics(), @options.tenantEventConsumer.dataEventName

      MundoSocketBase.disableTenantEventConsumer = () ->
        @unbindTopics @getTenantEventConsumerTopics()

      MundoSocketBase.getTenantEventConsumerTopics = () ->
        if angular.isArray @options.tenantEventConsumer.identifiers
          return (@options.tenantEventConsumer.singleTopic.replace('{identifier}', x) \
            for x in @options.tenantEventConsumer.identifiers)

        [@options.tenantEventConsumer.topic]

      MundoSocketBase.toggleSubscriptionConsumer = (active, identifiers = @options.subscriptionConsumer.identifiers) ->
        active = active or false
        @options.subscriptionConsumer.enabled = active

        # Important: _ALWAYS_ disable the consumer before overriding the
        # identifiers, because every time the identifiers change they could
        # modify the topics this consumer should bind to
        @disableSubscriptionConsumer()
        @options.subscriptionConsumer.identifiers = identifiers

        if @options.subscriptionConsumer.enabled
          @enableSubscriptionConsumer()

      MundoSocketBase.enableSubscriptionConsumer = () ->
        @bindTopicEvents @getSubscriptionConsumerTopics(), @options.subscriptionConsumer.dataEventName

      MundoSocketBase.disableSubscriptionConsumer = () ->
        @unbindTopics @getSubscriptionConsumerTopics()

      MundoSocketBase.getSubscriptionConsumerTopics = () ->
        if angular.isArray @options.subscriptionConsumer.identifiers
          return (@options.subscriptionConsumer.singleTopic.replace('{identifier}', x) \
            for x in @options.subscriptionConsumer.identifiers)

        [@options.subscriptionConsumer.topic]

      MundoSocketBase
  ]
